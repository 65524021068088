import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, concat, EMPTY, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, switchMap, finalize, take } from 'rxjs/operators';

export interface LoginForm {
  phone: string;
  password: string;
  method: string;
  endpoint: string;
  body?: any;
  observer: any;
}
interface Channel2Request {
    method: string;
    endpoint: string;
    body?: any;
    observer: any;  // The observer to handle the response
  }
  
@Injectable({
    providedIn: 'root',
  })
  export class ApiService {
    private isChannel2InProgress: boolean = false;
    private cancelChannel2Request: Subscription | null = null;
    private channel2Queue: Channel2Request[] = []; // Queue to hold requests when a Channel 2 request is in progress
  
  
    constructor(private http: HttpClient) {}
  
    // Existing methods for Channel 1 (No changes needed)
    get(endPoint: string): Observable<any> {
      return this.http.get(environment.API_URL + endPoint);
    }
  
    post(endPoint: string, body: any): Observable<any> {
      return this.http.post(environment.API_URL + endPoint, body);
    }
  
    put(endPoint: string, body: any): Observable<any> {
      return this.http.put(environment.API_URL + endPoint, body);
    }
  
    delete(endPoint: string): Observable<any> {
      return this.http.delete(environment.API_URL + endPoint);
    }
  
    // // Channel 2 request method
    // // private requestChannel2(method: string, endPoint: string, body?: any): Observable<any> {
    // //   return new Observable((observer) => {
    // //     // If a Channel 1 request is in progress, we cancel the current Channel 2 request
    // //     if (this.isChannel2InProgress) {
    // //       if (this.cancelChannel2Request) {
    // //         this.cancelChannel2Request.unsubscribe();
    // //       }
    // //       this.channel2Queue.push({ method, endpoint: endPoint, body });
    // //       return;
    // //     }
  
    // //     // Mark Channel 2 request as in progress
    // //     this.isChannel2InProgress = true;
  
    // //     const apiCall = this.makeApiCall(method, endPoint, body);
  
    // //     // Execute the API request
    // //     this.cancelChannel2Request = apiCall.subscribe({
    // //       next: (response) => {
    // //         observer.next(response);
    // //         observer.complete();
  
    // //         // After the request completes, process the next one in the queue
    // //         if (this.channel2Queue.length > 0) {
    // //           const nextRequest = this.channel2Queue.shift();
    // //           if (nextRequest) {
    // //             this.requestChannel2(nextRequest.method, nextRequest.endpoint, nextRequest.body).subscribe();
    // //           }
    // //         } else {
    // //           this.isChannel2InProgress = false;
    // //         }
    // //       },
    // //       error: (error) => {
    // //         observer.error(error);
    // //         this.isChannel2InProgress = false;
    // //       }
    // //     });
    // //   });
    // // }
  
    // private requestChannel2(method: string, endPoint: string, body?: any): Observable<any> {
    //     return new Observable((observer) => {
    //       // If a Channel 1 request is in progress, we cancel the current Channel 2 request
    //       if (this.isChannel2InProgress) {
    //         if (this.cancelChannel2Request) {
    //           this.cancelChannel2Request.unsubscribe();
    //         }
    
    //         // Queue the current request if it is canceled
    //         this.channel2Queue.push({ method, endpoint: endPoint, body, observer });
    //         return;
    //       }
    
    //       // Mark Channel 2 request as in progress
    //       this.isChannel2InProgress = true;
    
    //       const apiCall = this.makeApiCall(method, endPoint, body);
    
    //       // Execute the API request
    //       this.cancelChannel2Request = apiCall.subscribe({
    //         next: (response) => {
    //           observer.next(response);
    //           observer.complete();
    
    //           // After the request completes, process the next one in the queue
    //           if (this.channel2Queue.length > 0) {
    //             const nextRequest = this.channel2Queue.shift();
    //             if (nextRequest) {
    //               this.processQueuedRequest(nextRequest); // Resumes the next request
    //             }
    //           } else {
    //             this.isChannel2InProgress = false;
    //           }
    //         },
    //         error: (error) => {
    //           observer.error(error);
    //           this.isChannel2InProgress = false;
    //         }
    //       });
    //     });
    //   }
    
    //   // Helper function to process a queued request
    //   private processQueuedRequest(request: Channel2Request) {
    //     const apiCall = this.makeApiCall(request.method, request.endpoint, request.body);
    
    //     // Execute the queued request
    //     this.cancelChannel2Request = apiCall.subscribe({
    //       next: (response) => {
    //         request.observer.next(response);
    //         request.observer.complete();
    //       },
    //       error: (error) => {
    //         request.observer.error(error);
    //       }
    //     });
    // }
  
    //   // Method for making API calls (you can replace this with your actual implementation)
    //    qmakeApiCall(method: string, endpoint: string, body?: any): Observable<any> {
    //     // Implement your API call logic here
    //     // For now, returning a dummy observable
    //     return new Observable((observer) => {
    //       // Simulate an API response
    //       setTimeout(() => {
    //         observer.next({ data: 'response data' });
    //         observer.complete();
    //       }, 1000);
    //     });
    //   }
    
    
    //   // Method for making API calls (you can replace this with your actual implementation)
    // //   private makeApiCall(method: string, endpoint: string, body?: any): Observable<any> {
    // //     // Implement your API call logic here
    // //     // For now, returning a dummy observable
    // //     return new Observable((observer) => {
    // //       // Simulate an API response
    // //       setTimeout(() => {
    // //         observer.next({ data: 'response data' });
    // //         observer.complete();
    // //       }, 1000);
    // //     });
    // //   }
    




    // // Helper method to call API
    // private makeApiCall(method: string, endPoint: string, body?: any): Observable<any> {
    //   if (method === 'POST') {
    //     return this.post(endPoint, body);
    //   }
    //   if (method === 'GET') {
    //     return this.get(endPoint);
    //   }
    //   if (method === 'PUT') {
    //     return this.put(endPoint, body);
    //   }
    //   if (method === 'DELETE') {
    //     return this.delete(endPoint);
    //   }
    //   return new Observable();
    // }
  
    // // Public method for Channel 2 POST requests
    // requestChannel2Post(endPoint: string, body: any): Observable<any> {
    //   return this.requestChannel2('POST', endPoint, body);
    // }
  
    // // Public method for Channel 2 GET requests
    // requestChannel2Get(endPoint: string): Observable<any> {
    //   return this.requestChannel2('GET', endPoint);
    // }
  }