export const INDEX = 24;

export const CLIENTS = [
    {index: 1, appId: 'com.diet.master', appName: 'Diet Fit', identifier: 'testing'},
    {index: 2, appId: 'com.go.abieta', appName: 'Abieta', identifier: 'abietadm'}, // ios: com.goabieta // android: com.go.abieta - 25nov
    {index: 3, appId: 'com.locarb', appName: 'Locarb', identifier: 'locarbdm'}, // ios: com.locarb // android: com.locarb.kwt - 24nov
    {index: 4, appId: 'com.counted', appName: 'Counted', identifier: 'counteddm'}, // - 25nov
    {index: 5, appId: 'com.envisiondiet', appName: 'Envision Diet', identifier: 'envisiondm'},
    {index: 6, appId: 'com.dietcircle', appName: 'Diet Circle', identifier: 'dietcircledm'}, // - 25nov
    {index: 7, appId: 'fit.dietmaster.fitstationdm', appName: 'Fit Station', identifier: 'fitstationdm'}, // - 25nov
    {index: 8, appId: 'fit.dietmaster.chefpaul', appName: 'Chef Paul', identifier: 'chefpaul'}, 
    {index: 9, appId: '-', appName: 'My Chef', identifier: 'mychef'}, 
    {index: 10, appId: 'fit.dietmaster.eatsmart', appName: 'Eat Smart KW', identifier: 'eatsmart'}, // - 25 nov
    {index: 11, appId: 'fit.dietmaster.healthychoices', appName: 'Healthy Choices BH', identifier: 'healthychoices'},
    {index: 12, appId: 'fit.dietmaster.xfit', appName: 'X Fit KW', identifier: 'xfit'},
    {index: 13, appId: 'fit.dietmaster.nutrilifedm', appName: 'Nutri Life KSA', identifier: 'nutrilifedm'}, // - 25nov
    {index: 14, appId: 'fit.dietmaster.appetitordm', appName: 'Appetitor', identifier: 'appetitordm'},
    {index: 15, appId: 'fit.dietmaster.healthchart', appName: 'Health Chart', identifier: 'healthchart'},
    {index: 16, appId: 'fit.dietmaster.eightysixhealthy', appName: '86 Healthy', identifier: '86healthy'},
    {index: 17, appId: 'fit.dietmaster.rightdiet', appName: 'Right Diet', identifier: 'rightdiet'},
    {index: 18, appId: 'com.diet.prep', appName: 'Prep Diet', identifier: 'prepdiet'},
    {index: 19, appId: 'fit.dietmaster.xdiet', appName: 'XDiet', identifier: 'xdiet'},
    {index: 20, appId: 'com.abietaqtr', appName: 'Abieta Qtr', identifier: 'abietaqtrdm'},
    {index: 21, appId: 'com.kocmgrmeals', appName: 'NCC Meals', identifier: 'kocmgrmeals'},
    {index: 22, appId: 'com.diet.master', appName: 'Diet Fit Staging', identifier: 'dietfit'}, //staging only
    {index: 23, appId: 'com.swaps.diet', appName: 'Swaps Diet', identifier: 'swaps'},
    {index: 24, appId: 'com.roots.diet', appName: 'Roots Diet', identifier: 'roots'}, // - 20 nov
    {index: 25, appId: 'com.purediet', appName: 'Pure Diet', identifier: 'pure-diet'},
    {index: 26, appId: 'com.blackened', appName: 'BlackenedKW', identifier: 'blackened'}, // - 25 nov
    {index: 27, appId: 'com.go.abieta', appName: 'Abieta Qtr', identifier: 'abietaqtrdm'},
    
];

// EatSmart
// Locarb
// Fitstation
// nutrilife
// rightdiet
// Counted

// Abieta
// blackened
// DietCircle
// healthchart
// roots
// abietaqtr
