import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Storage } from "@ionic/storage-angular";
import * as moment from "moment";
import { AuthService } from "../../app/services/auth.service";
import { environment } from "src/environments/environment";
import { ApiService } from "../provider/api.service";
import { endpoints } from "src/app/constant/endpoints";
import { first } from "rxjs/operators";
import { log } from "console";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class DataService {
  // private cachedData: { [date: string]: any } = {};
  cachedData: { [date: string]: { [boxType: string]: any } } = {};
// pendingRequests: { [date: string]: { [boxType: string]: Promise<any> } } = {};
  private currentMonth: number;
  private daysArray: string[] = [];
  customer: any;
  availableDates: any[];
  private dayConfig: any;
  weekDayArray = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  weekDayOff: number;
  weekDay: string;

  dayConfigEntry: any;
  mealsCount: any;
  snacksCount: any;
  is_variable_carbs: any;
  isForceFixedMenu: any;
  selectionLimit: unknown[];
  selectedSubscriptionID: any;
  min_calories: any;
  max_calories: any;
  max_scaled_carbs: any;
  db: string;
  pendingRequestsDay: { [key: string]: Promise<any> } = {};
  pendingRequests: { [key: string]: Promise<any> } = {};
  dayConfigmain: any;
  availableDatesmain: any;
  private worker: Worker | null = null;
  authToken: any;

  constructor(private http: HttpClient, private storage: Storage, private platform: Platform, private authService: AuthService, private apiService: ApiService) {
    this.initializeStorage();

    if (typeof Worker !== "undefined") {
      this.worker = new Worker(new URL("../../app/worker.worker.ts", import.meta.url));
    } else {
      console.error("Web Workers are not supported in this environment.");
    }
  }

  async fetchData(date: string, boxType: string, customerId: string, authToken: string): Promise<any> {
    authToken = this.authService.getAuthToken();
    return new Promise<any>((resolve, reject) => {
      this.worker.onmessage = (event) => {
        const { success, data, error } = event.data;
        if (success) {
          resolve(data);
        } else {
          reject(new Error(error));
        }
      };
  
      this.worker.postMessage({
        action: "fetchData",
        date,
        boxType,
        customerId,
        authToken,
      });
    });
  }
  initializeWorker(): void {
    if (typeof Worker !== 'undefined') {
      if (!this.worker) {
        this.worker = new Worker(new URL('../../app/worker.worker.ts', import.meta.url), { type: 'module' }); // Replace with your worker file path
        console.log("Worker initialized.");
        this.worker.onmessage = (event) => {
          console.log('Message from worker:', event.data);
        };
        this.worker.onerror = (error) => {
          console.error('Worker error:', error);
        };
      } else {
        console.log("Worker is already active.");
      }
    } else {
      console.error("Web Workers are not supported in this environment.");
    }
  }
  terminateWorker(): void {
    if (this.worker) {
      this.worker.terminate(); // Kill the worker
      this.worker = null; // Clean up the reference
      console.log("Worker has been terminated.");
    } else {
      console.log("No active worker to terminate.");
    }
  }


  async initializeStorage() {
    this.customer = await this.authService.getAuthUser();
    await this.storage.create().then(() => {
      this.storage.get("cachedData").then((storedData) => {
        if (storedData) {
          this.cachedData = storedData;
        }
      });

      this.storage.get("days").then((availableDatesm) => {
        if (availableDatesm) {
          this.availableDatesmain = availableDatesm;
        }
      });

      this.storage.get("dayConfig").then((dayConfigm) => {
        if (dayConfigm) {
          this.dayConfigmain = dayConfigm;
        }
      });
    });
  }

  getMySubscription = async (customerId: number): Promise<void> => {
    this.customer = await this.authService.getAuthUser();
    this.dayConfig = {};
    this.availableDates = [];

    try {
      const response = await this.apiService.get(`${endpoints.mySubscriptionsnew}?customer_id=${customerId}`).pipe(first()).toPromise();

      if (response.success && response.data?.subscriptions) {
        const subscriptionArray = response.data.subscriptions;

        if (subscriptionArray.length > 0) {
          const subscriptions = subscriptionArray;
          this.availableDates = this.getFilteredDates(subscriptions);

          this.availableDates.forEach((date) => {
            if (moment(date).isValid()) {
              if (moment(date).isSameOrAfter(moment(), "day")) {
                let dayConfigEntry: any = {
                  date: date,
                  is_variable_carbs: false,
                  isForceFixedMenu: false,
                  selectionLimit: 0,
                  selectedSubscriptionID: null,
                  default_scaled_carbs: 0,
                  mealsCount: 0,
                  snacksCount: 0,
                  min_calories: 0,
                  max_calories: 9999999,
                  max_scaled_carbs: 0,
                  weekDayOff: null,
                  weekDay: null,
                  subscriptLastDate: null,
                  db: "false",
                };

                subscriptions.forEach((subscription) => {
                  const startDate = subscription.start_date ? moment(subscription.start_date).format("YYYY-MM-DD") : null;
                  const endDate = subscription.end_date ? moment(subscription.end_date).format("YYYY-MM-DD") : null;

                  if (startDate && endDate && moment(date).isBetween(startDate, endDate, undefined, "[]")) {
                    let baseDayConfigEntry = {
                      date,
                      is_variable_carbs: subscription.detail?.is_variable_carbs || false,
                      isForceFixedMenu: subscription.detail?.force_fixed_menu || false,
                      selectionLimit: subscription.detail?.selection_limit || 0,
                      selectedSubscriptionID: subscription.id || null,
                      mealsCount: Number(subscription.detail?.total_meals) || 0,
                      snacksCount: Number(subscription.detail?.total_snacks) || 0,
                      min_calories: subscription.detail?.minimum_calories || 0,
                      max_calories: subscription.detail?.maximum_calories || 9999999,
                      max_scaled_carbs: subscription.detail?.maximum_carbs || 0,
                      default_scaled_carbs: subscription.detail?.default_carbs || 50,
                      weekDayOff: subscription.detail?.day_off ? Number(subscription.detail.day_off) : 5,
                      weekDay: this.weekDayArray[subscription.detail?.day_off ? Number(subscription.detail.day_off) : 5],
                      subscriptLastDate: endDate,
                      db: subscription.double_box_dates?.includes(date) ? "true" : "false",
                    };

                    this.dayConfig[date] = baseDayConfigEntry;
                  }
                });
              }
            }
          });

          await this.storage.set("days", this.availableDates);
          await this.storage.set("dayConfig", this.dayConfig);
          
        }
      }
    } catch (error) {
      throw error;
    }
  };

  async getDayConfigData(): Promise<any> {
    return await this.storage.get("dayConfig");
  }
  async getDaysData(): Promise<any> {
    return await this.storage.get("days");
  }

  async loadData(date: string, boxType: string): Promise<any> {
    const inputDate = moment(date).format("YYYY-MM-DD");
    const dbDay = boxType === "true" ? "true" : "false";

    try {
        // Fetch all available days
        const allDays: string[] = await this.getDaysData();
        if (!allDays || allDays.length === 0) {
            console.error("No available days to load.");
            return null;
        }

        const inputDateMoment = moment(date);

        // Filter and process days sequentially starting from the input date
        for (const day of allDays) {
            const formattedDay = moment(day).format("YYYY-MM-DD");

            // Check if the day already exists in the cache and skip
            if (this.cachedData?.[formattedDay]?.['singleBox'] || this.cachedData?.[formattedDay]?.['doubleBox']) {
                console.log(`Data for ${formattedDay} already cached. Skipping...`);
                continue;
            }

            // Load data only if not cached
            if (moment(day).isSameOrAfter(inputDateMoment)) {
                console.log(`Loading data for ${formattedDay}`);

                const startTime = Date.now(); // Record the start time

                try {
                    await this.overwriteDayData(formattedDay, dbDay); // Call API
                    const endTime = Date.now(); // Record the end time

                    const timeTaken = endTime - startTime; // Calculate time taken
                    if (timeTaken < 2000) { // If response took less than 1 second
                        const delay = 2000 - timeTaken; // Calculate remaining delay
                        console.log(`Delaying for ${delay} ms to maintain 1 second gap.`);
                        await this.delay(delay); // Introduce delay
                    }

                    console.log(`Data for ${formattedDay} stored in cache.`);
                } catch (error) {
                    console.error(`Error loading data for ${formattedDay}:`, error);
                }
            }
        }

        // Return the data for the requested input date
        return this.cachedData[inputDate] || null;
    } catch (error) {
        console.error(`Error in loadData:`, error);
        throw error;
    }
}

// Helper function to introduce a delay
private delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
  clearStorage() {
    // this.terminateWorker()
    this.storage
      .clear()
      .then(() => {
        console.log("Storage cleared");

        this.cachedData = null;
        this.availableDatesmain = [];
        this.dayConfigmain = [];
      })
      .catch((error) => {
        console.error("Error clearing storage:", error);
      });
  }

  async overwriteDayData(date: string, boxType: string): Promise<any> {
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      if (!this.cachedData) {
        this.cachedData = {}; // Initialize cachedData as an empty object if it's null or undefined
      }
      // Initialize cache for the day if it doesn't exist
      if (!this.cachedData[formattedDate]) {
        this.cachedData[formattedDate] = {};
      }
  
      const db = boxType === "true" ? "doubleBox" : "singleBox";
  
      // Fetch data using the worker
      const updatedData = await this.fetchData(date, boxType, this.customer.id, this.authToken);
      console.log(`API response for ${formattedDate}:`, updatedData);
  
      // Store the fetched data in cache
      this.cachedData[formattedDate][db] = updatedData;
  
      // Persist the updated cache
      await this.storage.set("cachedData", this.cachedData);
  
      return this.cachedData[formattedDate];
    } catch (error) {
      console.error(`Error in overwriteDayData for ${date}:`, error);
      throw error;
    }
  }
  async updateDayData(date: string, db: string) {
   

  
        try {
          const formattedDate = moment(date).format("YYYY-MM-DD");
          if (!this.cachedData) {
            this.cachedData = {}; // Initialize cachedData as an empty object if it's null or undefined
          }
          // Initialize cache for the day if it doesn't exist
          if (!this.cachedData[formattedDate]) {
            this.cachedData[formattedDate] = {};
          }
      
          const dba = db === "true" ? "doubleBox" : "singleBox";
      
          // Fetch data using the worker
          const red: any = await this.http
          .get(`${environment.API_URL}menu_builders?date=${date}&is_double_box=${db}&customer_id=${this.customer.id}`)
          .toPromise();

          const updatedData = red.data.menu_builders;
          // const updatedData = await this.fetchData(date, boxType, this.customer.id, this.authToken);
          console.log(`API response for ${formattedDate}:`, updatedData);
      
          // Store the fetched data in cache
          this.cachedData[formattedDate][dba] = updatedData;
      
          // Persist the updated cache
          await this.storage.set("cachedData", this.cachedData);
      
          return this.cachedData[formattedDate];
        } catch (error) {
          console.error(`Error in overwriteDayData for ${date}:`, error);
          throw error;
        }
  }
  async updateday(date: string): Promise<any> {
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
      if (!this.cachedData) {
        this.cachedData = {}; // Initialize cachedData as an empty object if it's null or undefined
      }
      if (!this.cachedData[formattedDate]) {
        this.cachedData[formattedDate] = {};
      }

      const db = this.dayConfig[date]?.db;
      if (db == true) {
        // this.customer = await this.authService.getAuthUser();
        const response: any = await this.http.get(`${environment.API_URL}menu_builders?date=${formattedDate}&is_double_box=false&customer_id=${this.customer.id}`).toPromise();

        const updatedData = response.data.menu_builders;

        this.cachedData[formattedDate]["singleBox"] = updatedData;

        await this.storage.set("cachedData", this.cachedData);

        const response2: any = await this.http.get(`${environment.API_URL}menu_builders?date=${formattedDate}&is_double_box=${db}&customer_id=${this.customer.id}`).toPromise();

        const updatedData2 = response2.data.menu_builders;

        this.cachedData[formattedDate]["doubleBox"] = updatedData;

        await this.storage.set("cachedData", this.cachedData);
      } else {
        // this.customer = this.authService.getAuthUser();
        const response: any = await this.http.get(`${environment.API_URL}menu_builders?date=${formattedDate}&is_double_box=${db}&customer_id=${this.customer.id}`).toPromise();

        const updatedData = response.data.menu_builders;

        this.cachedData[formattedDate]["singleBox"] = updatedData;

        await this.storage.set("cachedData", this.cachedData);
      }

      return this.cachedData[formattedDate];
    } catch (error) {
      throw error;
    }
  }

  async removeDoubleBoxEntry(date: string): Promise<void> {
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");

      if (!this.cachedData[formattedDate]) {
        return;
      }

      if (this.cachedData[formattedDate]["doubleBox"]) {
        delete this.cachedData[formattedDate]["doubleBox"];
      }
      
    } finally {
      await this.storage.set("cachedData", this.cachedData);
    }
  }

  async fetchDataForDate(date: string, db: string): Promise<any> {
    try {
      const formattedDate = moment(date).format("YYYY-MM-DD");
  
      // Initialize cachedData if null or undefined
      if (!this.cachedData) {
        this.cachedData = {};
      }
  
      // Determine the box type (single or double)
      const boxType = db === "true" ? "doubleBox" : "singleBox";
  
      // Check if the specific data is already cached
      if (this.cachedData[formattedDate] && this.cachedData[formattedDate][boxType]) {
        return this.cachedData[formattedDate];
      }
  
      // Fetch data for the given date and box type
      const updatedData = await this.updateDayData(date, db);
  
      // Return the updated cached data for the specific date
      return this.cachedData[formattedDate];
    } catch (error) {
      console.error(`Error fetching data for ${date}:`, error);
      throw error;
    }
  }

  generateDateRange(startDate: string, endDate: string): string[] {
    let dates: string[] = [];
    let currentDate = moment(startDate, "DD MMMM, YYYY");
    const endMoment = moment(endDate, "DD MMMM, YYYY");

    while (currentDate.isBefore(endMoment) || currentDate.isSame(endMoment)) {
      dates.push(currentDate.format("YYYY-MM-DD"));
      currentDate = currentDate.add(1, "day");
    }
    return dates;
  }
  getFilteredDates(subscriptions: any[]): string[] {
    let allDates: string[] = [];
    const today = moment().startOf("day");
  
    subscriptions.forEach((subscription) => {
      const {
        start_date,
        end_date,
        freeze_dates = [], // Default to an empty array
        double_box_dates = [], // Default to an empty array
        detail = {},
      } = subscription;
  
      const allGeneratedDates = this.generateDateRange(start_date, end_date);
  
      // Ensure all data is in the expected format
      const freezeDatesSet = new Set(Array.isArray(freeze_dates) ? freeze_dates : []);
      const doubleBoxDatesSet = new Set(Array.isArray(double_box_dates) ? double_box_dates : []);
      const dayOffSet = new Set(Array.isArray(detail.day_off) ? detail.day_off : []);
  
      // Filter out freeze dates, day off dates, and past dates
      let filteredDates = allGeneratedDates.filter((date) => {
        const momentDate = moment(date);
        const dayOfWeek = momentDate.day();
        return (
          !freezeDatesSet.has(date) &&
          !dayOffSet.has(dayOfWeek) &&
          momentDate.isSameOrAfter(today)
        );
      });
  
      // Process double box dates
      doubleBoxDatesSet.forEach((doubleBoxDate) => {
        const doubleBoxMoment = moment(doubleBoxDate);
        const formattedDoubleBoxDate = doubleBoxMoment.format("YYYY-MM-DD");
  
        // If the double box date is valid and not already excluded
        if (!freezeDatesSet.has(formattedDoubleBoxDate) && !dayOffSet.has(doubleBoxMoment.day())) {
          if (!filteredDates.includes(formattedDoubleBoxDate)) {
            filteredDates.push(formattedDoubleBoxDate);
          }
  
          // Handle day after logic
          const dayAfter = doubleBoxMoment.clone().add(1, "day").format("YYYY-MM-DD");
          if (filteredDates.includes(dayAfter)) {
            const dayOfWeekAfter = doubleBoxMoment.clone().add(1, "day").day();
  
            // Replace or skip based on dayOffSet
            if (!dayOffSet.has(dayOfWeekAfter)) {
              filteredDates = filteredDates.map((date) =>
                date === dayAfter ? formattedDoubleBoxDate : date
              );
            }
          }
        }
      });
  
      // Add filtered dates for the current subscription to allDates
      allDates = allDates.concat(filteredDates);
    });
  
    // Remove duplicates, sort dates, and ensure no past dates are included
    allDates = [...new Set(allDates)]
      .sort((a, b) => moment(a).diff(moment(b)))
      .filter((date) => moment(date).isSameOrAfter(today));
  
    return allDates;
  }
  updateDayConfigDb = async (dateGiven: string, isDoubleBoxSelected: boolean): Promise<void> => {
    try {
      const storedDayConfig = await this.storage.get("dayConfig");
      const dayConfig = storedDayConfig || {};

      if (dayConfig[dateGiven]) {
        dayConfig[dateGiven].db = String(isDoubleBoxSelected);
        this.dayConfig = dayConfig;
      } else {
        dayConfig[dateGiven] = {
          db: String(isDoubleBoxSelected),
          mealsCount: 0,
          snacksCount: 0,
          is_variable_carbs: false,
          default_scaled_carbs: 0,
          isForceFixedMenu: false,
          selectionLimit: [],
          selectedSubscriptionID: null,
          min_calories: 0,
          max_calories: 9999999,
          max_scaled_carbs: 0,
        };
        this.dayConfig = dayConfig;
      }
    } catch (error) {
    } finally {
      await this.storage.set("dayConfig", this.dayConfig);
    }
  };
}
