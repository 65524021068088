export const endpoints = {
    slider: 'banners',
    configuration: 'initial',

    login: 'login',
    register: 'register',
    logout: 'logout',
    profile: 'profile',
    changePassword: 'change/password',
    resetPassword: 'password/reset',
    updatePassword: 'password/update',
    sendOTP: 'resend/otp',
    verifyOTP: 'verify/otp',
    sendRating: 'feedback',

    subscriptions: 'subscriptions',
    mySubscriptionsnew: 'customer_subscriptions/new',
    mySubscriptions: 'customer_subscriptions',
    buySubscriptions: 'customer_subscriptions',
    subData: 'my-customer-subscriptions',
    

    subscriptionSubscribe: 'subscription/subscribe',
    subscriptionValidate: 'subscription/validate-subscribe',
    subscriptionCustomValidate: 'customer_subscriptions/purchase/custom',
    subscriptionCustom: 'subscription/custom-subscribe',
    subscriptionAddons: 'subscription-addons',

    subscriptionCoupon: 'coupons/validate',
    mealBooking: 'select/menu_items',
    clearMenu: 'clear/menu_items',
    subscriptionStart: 'subscription/start',
    subscriptionFreeze: 'customer_subscriptions/freeze',
    subscriptionUnFreeze: 'customer_subscriptions/unfreeze',
    subscriptionUpgrade: 'customer_subscriptions/upgrade',
    subscriptionCancel: 'customer_subscriptions/cancel',
    allergies: 'allergies',
    dislikes: 'dislikes',
    consultants: "consultants",
    consultations: "consultations",
    bookConsultants: "book/consultations",
    generatePayURL: "customer_subscriptions/generate/charge_url",

    calendar: 'calendar',
    openAddress: 'open-address',
    deliverAddressDates: 'customer_subscriptions/set/delivery/address',
    addresses: 'addresses',
    address: 'address',
    areas: 'areas',
    slots: 'area/slots',
    menus: 'menu_builders',
    menu_replace: 'replaceable/menu_builders',
    menuSubscriptions: 'menu_subscriptions',
    menuDetails: 'menu-details',
    menuItems: 'menu_items',
    order: 'order',
    orders: 'orders',
    cms: 'pages',
    deleteAccount: 'delete',
    deactivate: 'deactivate',
    celebrities: "celebrities",
    notifications: "notifications",
    notificationsUpdate: "notifications/update",
    posts: 'posts',
    reaction: 'posts/reaction',
    comments: 'comments',
    calculate_calories: 'calculate/calories',
    assign_default_menu_items: 'assign/default_menu_items',
    myReferral: 'referral',
    mysubs:'my-customer-subscriptions'

};