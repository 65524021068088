import { Input,AfterViewInit, Renderer2, ElementRef  } from '@angular/core';
import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
@Component({
  selector: 'app-svg-background',
  templateUrl: './svg-background.component.html',
  styleUrls: ['./svg-background.component.scss']
})
export class SvgBackgroundComponent { 
  private readonly STAR_COLOR = 'var(--ion-color-secondary)';
  private readonly STAR_SIZE = 0.5;
  private readonly STAR_MIN_SCALE = 0.1;
  private readonly OVERFLOW_THRESHOLD = 50;
  private readonly STAR_COUNT = (window.innerWidth + window.innerHeight) / 10;
  private canvas: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;
  private scale = 1; // device pixel ratio
  private width: number;
  private height: number;
  private stars: { x: number; y: number; z: number }[] = [];
  private pointerX: number | null = null;
  private pointerY: number | null = null;
  private velocity = { x: 0, y: 0, tx: 0, ty: 0, z: 0.0005 };
  private touchInput = true;
  private animationFrameId: number | null = null;

  private isAnimating = true;
  private scrollInterval: any;
  private scrollSpeed: number = 5; // Adjust scrolling speed as needed
  private directionX: number = -1; // 1 for right, -1 for left
  private directionY: number = -1; // 1 for down, -1 for up
  animationInterval: any;
  animationFrame: number;
  rect: SVGRectElement;
  rect2: SVGRectElement;
  rect3: SVGRectElement;
  rect4: SVGRectElement;
  rect5: SVGRectElement;
  grad1: any;
  grad2: SVGGradientElement;
  grad3: SVGGradientElement;

  constructor(
    private renderer: Renderer2,
     private el: ElementRef
    ) {}
    
  //   ngOnInit() {
  //     console.log('SvgBackgroundComponent initialized');
  //   }
  // @Input() primaryColor: string = 'var(--p2)';
  // @Input() secondaryColor: string = 'var(--s2)';

  // ngAfterViewInit(): void {
  //   // this.startAutoScroll();
  // }

  // ngOnDestroy(): void {
  //   // if (this.scrollInterval) {
  //   //   clearInterval(this.scrollInterval);
  //   // }
  // }


  


  // ngOnInit(): void {
  //   this.canvas = document.querySelector('canvas')!;
  //   this.context = this.canvas.getContext('2d')!;
  //   this.generate();
  //   this.resize();
  //   this.step();
  //   window.addEventListener('resize', this.resize.bind(this));
  //    this.grad1 = document.getElementById('Gradient1') as unknown as SVGGradientElement;
  //    this.grad2 = document.getElementById('Gradient2') as unknown as SVGGradientElement;
  //    this.grad3 = document.getElementById('Gradient3') as unknown as SVGGradientElement;
  //   this.startGradientAnimation(this.grad1,5,80,1);
  //   this.startGradientAnimation(this.grad2,0,25,1);
  //   this.startGradientAnimation(this.grad3,10,40,1);
    
  //   this.rect = document.getElementById('animatedRect') as unknown as SVGRectElement;
  //   this.rect2 = document.getElementById('arect2') as unknown as SVGRectElement;
  //   this.rect3 = document.getElementById('arect3') as unknown as SVGRectElement;
  //   this.rect4 = document.getElementById('arect4') as unknown as SVGRectElement;
  //   this.rect5 = document.getElementById('arect5') as unknown as SVGRectElement;
  //   this.animateRect(this.rect,5,50,-10,25,360,1,1);
  //   this.animateRect(this.rect2,-5,15,0,25,36,1,1);
  //   this.animateRect(this.rect3,-100,45,5,95,160,1,1);
  //   this.animateRect(this.rect4,35,50,-50,50,60,1,1);
  //   this.animateRect(this.rect5,-25,90,20,75,360,1,1);
  // }

  // ngOnDestroy(): void {
  //   if (this.animationFrameId) {
  //     cancelAnimationFrame(this.animationFrameId);
  //   }
  //   if (this.animationFrame) {
  //     cancelAnimationFrame(this.animationFrame);
  //   }
  //   window.removeEventListener('resize', this.resize.bind(this));
  //   this.stopGradientAnimation();
  // }


  // startGradientAnimation(gradient: SVGGradientElement, fxValue,mfxValue,direction) {
  //   // const gradient = document.getElementById('Gradient1');

  //   if (gradient) {
  //     let fx=0;
  //     // let direction = -1; // Direction to animate

  //     this.animationInterval = setInterval(() => {
  //       // Update `fx` value
  //       fx += direction * 0.5;

  //       // Reverse direction if limits are reached
  //       if (mfxValue <= fx || fxValue >= fx) {
  //         direction *= -1;
  //       }

  //       // Set the new `fx` value
  //       gradient.setAttribute('fx', `${fx}%`);
  //     }, 500); // Adjust speed as needed
  //   }
  // }

  // stopGradientAnimation() {
  //   if (this.animationInterval) {
  //     clearInterval(this.animationInterval);
  //   }
  // }
  // animateRect(rect: SVGRectElement,x:number,mx:number,y:number,my:number,angle:number,xDirection:number,yDirection:number) {
  //   // const rect = document.getElementById('animatedRect');
  //   if (!rect) return;

  //   // Animation variables
  //   let a = x+2;
  //   let b = y+2;
  //   let c = angle-1;
  //   let d=0,e=0;
  //   // let xDirection = 1; // For oscillating x between 5% and 9%
  //   // let yDirection = 1; // For oscillating y between -20% and 5%

  //   const animate = () => {
  //     // Update x position
  //     a += xDirection * 0.02 * 0.2;
  //     if (a >= mx || a <= x) {
  //       xDirection *= -1;
  //     }

  //     // Update y position
  //     b += yDirection * 0.02 * 0.2;
  //     if (b >= my || b <= y) {
  //       yDirection *= -1;
  //     }

  //     // Update rotation angle
  //     c += 0.4 * 0.2; // Adjust for smoother rotation
  //     // d += Math.abs((c - angle) * 0.002);
  //     // e += Math.abs((d - angle) * 0.002);
  //     d = Math.abs(a);
  //     e = Math.abs(b);
  //     if (c >= angle) {
  //       c = 0; // Reset to avoid overflow
  //     }
  //     // if ( d >= 960) {
  //     //   d = 0;
  //     // }
  //     // if (e >= 720) {
  //     //   e = 0;
  //     // }
      
  //     // console.log(a,b,c,d,e,'vars')

  //     // Apply updated values to the rect attributes
  //     rect.setAttribute('x', `${a}%`);
  //     rect.setAttribute('y', `${b}%`);
  //     rect.setAttribute('transform', `rotate(${c} ${d} ${e})`);

  //     // Request the next frame
  //     this.animationFrame = requestAnimationFrame(animate);
  //   };

  //   animate();
  // }
  // private generate(): void {
  //   for (let i = 0; i < this.STAR_COUNT; i++) {
  //     this.stars.push({
  //       x: 0,
  //       y: 0,
  //       z: this.STAR_MIN_SCALE + Math.random() * (1 - this.STAR_MIN_SCALE)
  //     });
  //   }
  // }

  // private placeStar(star: { x: number; y: number; z: number }): void {
  //   star.x = Math.random() * this.width;
  //   star.y = Math.random() * this.height;
  // }

  // private recycleStar(star: { x: number; y: number; z: number }): void {
  //   let direction = 'z';
  //   const vx = Math.abs(this.velocity.x);
  //   const vy = Math.abs(this.velocity.y);
  //   if (vx > 1 || vy > 1) {
  //     const axis = vx > vy ? (Math.random() < vx / (vx + vy) ? 'h' : 'v') : (Math.random() < vy / (vx + vy) ? 'v' : 'h');
  //     direction = axis === 'h' ? (this.velocity.x > 0 ? 'l' : 'r') : (this.velocity.y > 0 ? 't' : 'b');
  //   }
  //   star.z = this.STAR_MIN_SCALE + Math.random() * (1 - this.STAR_MIN_SCALE);
  //   if (direction === 'z') {
  //     star.z = 0.1;
  //     star.x = Math.random() * this.width;
  //     star.y = Math.random() * this.height;
  //   } else if (direction === 'l') {
  //     star.x = -this.OVERFLOW_THRESHOLD;
  //     star.y = this.height * Math.random();
  //   } else if (direction === 'r') {
  //     star.x = this.width + this.OVERFLOW_THRESHOLD;
  //     star.y = this.height * Math.random();
  //   } else if (direction === 't') {
  //     star.x = this.width * Math.random();
  //     star.y = -this.OVERFLOW_THRESHOLD;
  //   } else if (direction === 'b') {
  //     star.x = this.width * Math.random();
  //     star.y = this.height + this.OVERFLOW_THRESHOLD;
  //   }
  // }

  // private resize(): void {
  //   this.scale = window.devicePixelRatio || 1;
  //   this.width = window.innerWidth * this.scale;
  //   this.height = window.innerHeight * this.scale;
  //   this.canvas.width = this.width;
  //   this.canvas.height = this.height;
  //   this.stars.forEach(star => this.placeStar(star));
  // }

  // private step(): void {
  //   this.context.clearRect(0, 0, this.width, this.height);
  //   this.update();
  //   this.render();
  //   this.animationFrameId = requestAnimationFrame(this.step.bind(this));
  // }

  // private update(): void {
  //   this.velocity.tx *= 0.96;
  //   this.velocity.ty *= 0.96;
  //   this.velocity.x += (this.velocity.tx - this.velocity.x) * 0.8;
  //   this.velocity.y += (this.velocity.ty - this.velocity.y) * 0.8;
  //   this.stars.forEach(star => {
  //     star.x += this.velocity.x * star.z;
  //     star.y += this.velocity.y * star.z;
  //     star.x += (star.x - this.width / 2) * this.velocity.z * star.z;
  //     star.y += (star.y - this.height / 2) * this.velocity.z * star.z;
  //     star.z += this.velocity.z;
  //     if (star.x < -this.OVERFLOW_THRESHOLD || star.x > this.width + this.OVERFLOW_THRESHOLD || star.y < -this.OVERFLOW_THRESHOLD || star.y > this.height + this.OVERFLOW_THRESHOLD) {
  //       this.recycleStar(star);
  //     }
  //   });
  // }

  // private render(): void {
  //   this.stars.forEach(star => {
  //     this.context.beginPath();
  //     this.context.lineCap = 'round';
  //     this.context.lineWidth = this.STAR_SIZE * star.z * this.scale;
  //     this.context.globalAlpha = 0.5 + 0.5 * Math.random();
  //     this.context.strokeStyle = this.STAR_COLOR;
  //     this.context.moveTo(star.x, star.y);
  //     let tailX = this.velocity.x * 2, tailY = this.velocity.y * 2;
  //     if (Math.abs(tailX) < 0.1) tailX = 0.5;
  //     if (Math.abs(tailY) < 0.1) tailY = 0.5;
  //     this.context.lineTo(star.x + tailX, star.y + tailY);
  //     this.context.stroke();
  //   });
  // }

  // private movePointer(x: number, y: number): void {
  //   if (typeof this.pointerX === 'number' && typeof this.pointerY === 'number') {
  //     const ox = x - this.pointerX!;
  //     const oy = y - this.pointerY!;
  //     this.velocity.tx += (ox / 8 * this.scale) * (this.touchInput ? 1 : -1);
  //     this.velocity.ty += (oy / 8 * this.scale) * (this.touchInput ? 1 : -1);
  //   }
    
  //   this.pointerX = x;
  //   this.pointerY = y;
  // }

  // @HostListener('mousemove', ['$event'])
  // onMouseMove(event: MouseEvent): void {
  //   this.touchInput = true;
  //   this.movePointer(event.clientX, event.clientY);
  // }

  // @HostListener('touchmove', ['$event'])
  // onTouchMove(event: TouchEvent): void {
  //   this.touchInput = true;
  //   this.movePointer(event.touches[0].clientX, event.touches[0].clientY);
  //   event.preventDefault();
  // }

  // @HostListener('mouseleave')
  // onMouseLeave(): void {
  //   this.pointerX = null;
  //   this.pointerY = null;
  // }

}
