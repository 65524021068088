import { Animation, AnimationController } from '@ionic/angular';

const animationCtrl = new AnimationController();
export const getIonPageElement = (element: HTMLElement) => {
	if (element.classList.contains('ion-page')) {
		return element;
	}

	const ionPage = element.querySelector(
		':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs'
	);
	if (ionPage) {
		return ionPage;
	}
	// idk, return the original element so at least something animates and we don't have a null pointer
	return element;
};
export const pageTransition1 = (_: HTMLElement, opts: any) => {
	const backDirection = opts.direction === 'back';
	const enteringEl = opts.enteringEl;
	const leavingEl = opts.leavingEl;

	const enteringPageEl = getIonPageElement(enteringEl);

	const rootTransition = animationCtrl.create();

	const enterTransition = animationCtrl.create();
	const leavingTransition = animationCtrl.create();

	leavingTransition.addElement(getIonPageElement(leavingEl)).duration(600);

	enterTransition
		.addElement(enteringPageEl)
		.duration(600)
		.fill('both')
		.beforeRemoveClass('ion-page-invisible');

	if (!backDirection) {
		enterTransition
			.beforeStyles({ border: 'thin solid transparent' })
			.keyframes([
				{ offset: 0, transform: 'scale(1.2)' },
				{ offset: 1, transform: 'scale(1)' }
			])
			.afterClearStyles(['border']);

		leavingTransition
			.keyframes([
				{ offset: 0, opacity: 1 },
				{ offset: 1, opacity: 0.1 }
			]);
	} else {
		enterTransition
			.keyframes([
				{ offset: 0, opacity: 0.1 },
				{ offset: 1, opacity: 1 }
			]);

		leavingTransition
			.beforeStyles({ border: 'thin solid transparent' })
			.keyframes([
				{ offset: 0, transform: 'scale(1)' },
				{ offset: 1, transform: 'scale(1.2)' }
			])
			.afterClearStyles(['border']);
	}

	rootTransition.addAnimation([enterTransition, leavingTransition]);

	return rootTransition;

}
export const pageTransition = (baseEl: HTMLElement, opts?: any): Animation => {
    const DURATION = 600; // Duration of the transition in milliseconds
    const EASING = 'ease-in-out'; // Smooth easing function for the transition

    // Forward direction animation (navigating to a new page)
    if (opts.direction === 'forward') {
		const enteringAnimation = animationCtrl.create()
            .addElement(opts.enteringEl)
            .duration(DURATION)
            .easing(EASING)
            .keyframes([
                { offset: 0, opacity: '0', transform: 'scale(0.9) translateX(50%) translateZ(0px)' },
                { offset: 0.5, opacity: '0.5', transform: 'scale(0.95) translateX(25%) translateZ(0px)' },
                { offset: 1, opacity: '1', transform: 'scale(1) translateX(0%) translateZ(0px)' },
            ]);
			const leavingAnimation = animationCtrl.create()
            .addElement(opts.leavingEl)
            .duration(DURATION)
            .easing(EASING)
            .keyframes([
                { offset: 0, opacity: '1', transform: 'scale(1) translateX(0%) translateZ(0px)' },
                { offset: 0.5, opacity: '0.5', transform: 'scale(0.95) translateX(-12%) translateZ(0px)' },
                { offset: 1, opacity: '0', transform: 'scale(0.9) translateX(-50%) translateZ(-50px)' },
            ]);
			return animationCtrl.create().addAnimation([enteringAnimation, leavingAnimation]);
    } 
    // Backward direction animation (navigating back to the previous page)
    else {
        const enteringAnimation = animationCtrl.create()
            .addElement(opts.enteringEl)
            .duration(DURATION)
            .easing(EASING)
            .keyframes([
                { offset: 0, opacity: '0', transform: 'scale(0.95) translateX(-25%) translateZ(0px)' },
                { offset: 0.5, opacity: '0.5', transform: 'scale(0.98) translateX(-12%) translateZ(0px)' },
                { offset: 1, opacity: '1', transform: 'scale(1) translateX(0%) translateZ(0px)' },
            ]);

        const leavingAnimation = animationCtrl.create()
            .addElement(opts.leavingEl)
            .duration(DURATION)
            .easing(EASING)
            .keyframes([
                { offset: 0, opacity: '1', transform: 'scale(1) translateX(0%) translateZ(0px)' },
                { offset: 0.5, opacity: '0.5', transform: 'scale(0.95) translateX(12%) translateZ(0px)' },
                { offset: 1, opacity: '0', transform: 'scale(0.9) translateX(50%) translateZ(-50px)' },
            ]);

        return animationCtrl.create().addAnimation([enteringAnimation, leavingAnimation]);
    }
};
export const customAlertEnter = (baseEl: HTMLElement, opts?: any): Animation => {
	const DURATION = 600;
	//console.log('baseEl: ', baseEl);
	//console.log('opts: ', opts);

	const backdropAnimation = animationCtrl.create()
		.addElement(baseEl.querySelector('ion-backdrop'))
		.fromTo('opacity', 0.01, 0);

	const wrapperElem = baseEl.querySelector('.alert-wrapper') as HTMLElement;
	wrapperElem.style.top = '0';
	const wrapperAnimation = animationCtrl.create()
		.addElement(wrapperElem)
		.beforeStyles({ 'opacity': 1 })
		.fromTo('transform', `translateY(-${baseEl.clientHeight}px)`, 'translateY(0px)');

	return animationCtrl.create()
		.addElement(baseEl)
		.easing('cubic-bezier(.36, .66, .3, 1.0)')
		.duration(DURATION)
		.addAnimation([backdropAnimation, wrapperAnimation]);
}
export const customAlertLeave = (baseEl: HTMLElement, opts?: any): Animation => {
	const DURATION = 600;
	//console.log('baseEl: ', baseEl);
	//console.log('opts: ', opts);

	const backdropAnimation = animationCtrl.create()
		.addElement(baseEl.querySelector('ion-backdrop'))
		.fromTo('opacity', 0.01, 0);

	const wrapperElem = baseEl.querySelector('.alert-wrapper') as HTMLElement;
	wrapperElem.style.top = '0';
	const wrapperAnimation = animationCtrl.create()
		.addElement(wrapperElem)
		.beforeStyles({ 'opacity': 1 })
		.fromTo('transform', 'translateY(0px)', `translateY(${baseEl.clientHeight}px)`);

	return animationCtrl.create()
		.addElement(baseEl)
		.easing('cubic-bezier(.36, .66, .3, 1.0)')
		.duration(DURATION)
		.addAnimation([backdropAnimation, wrapperAnimation]);
}
export const customLoaderEnter = (baseEl: HTMLElement, opts?: any): Animation => {
	const DURATION = 600;
	//console.log('baseEl: ', baseEl);
	//console.log('opts: ', opts);

	const backdropAnimation = animationCtrl.create()
		.addElement(baseEl.querySelector('ion-backdrop'))
		.fromTo('opacity', 0.01, 0);

	const wrapperElem = baseEl.querySelector('.loading-wrapper') as HTMLElement;
	wrapperElem.style.top = '0';
	const wrapperAnimation = animationCtrl.create()
		.addElement(wrapperElem)
		.beforeStyles({ 'opacity': 1 })
		.fromTo('transform', `translateY(-${baseEl.clientHeight}px)`, 'translateY(0px)');

	return animationCtrl.create()
		.addElement(baseEl)
		.easing('cubic-bezier(.36, .66, .3, 1.0)')
		.duration(DURATION)
		.addAnimation([backdropAnimation, wrapperAnimation]);
}
export const customLoaderLeave = (baseEl: HTMLElement, opts?: any): Animation => {
	const DURATION = 600;
	//console.log('baseEl: ', baseEl);
	//console.log('opts: ', opts);

	const backdropAnimation = animationCtrl.create()
		.addElement(baseEl.querySelector('ion-backdrop'))
		.fromTo('opacity', 0.01, 0);

	const wrapperElem = baseEl.querySelector('.loading-wrapper') as HTMLElement;
	wrapperElem.style.top = '0';
	const wrapperAnimation = animationCtrl.create()
		.addElement(wrapperElem)
		.beforeStyles({ 'opacity': 1 })
		.fromTo('transform', 'translateY(0px)', `translateY(${baseEl.clientHeight}px)`);

	return animationCtrl.create()
		.addElement(baseEl)
		.easing('cubic-bezier(.36, .66, .3, 1.0)')
		.duration(DURATION)
		.addAnimation([backdropAnimation, wrapperAnimation]);
}
export const customModalEnter = (baseEl: HTMLElement, opts?: any): Animation => {
	const DURATION = 600;
	/*console.log('baseEl: ', baseEl);
	console.log('opts: ', opts);*/

	const backdropAnimation = animationCtrl.create()
		.addElement(baseEl.shadowRoot.querySelector('ion-backdrop')!)
		.fromTo('opacity', '0.01', '0');

	const wrapperAnimation = animationCtrl.create()
		.addElement(baseEl.shadowRoot.querySelector('.modal-wrapper')!)
		.keyframes([
			{ offset: 0, opacity: '0', transform: 'scale(1.1) translateY(20%) translateX(0%) ' },
			{ offset: 1, opacity: '0.99', transform: 'scale(1) translateY(0%) translateX(0%) ' },
		]);

	return animationCtrl.create()
		.addElement(baseEl)
		.easing('ease-out')
		.duration(DURATION)
		.addAnimation([backdropAnimation, wrapperAnimation]);
}
export const customModalLeave = (baseEl: HTMLElement): Animation => {
	return customModalEnter(baseEl).direction('reverse');
}

export const initListAnimation = (baseEl: HTMLElement): Animation => {
	const DURATION = 600;
	//console.log('baseEl: ', baseEl);
	//console.log('opts: ', opts);

	const backdropAnimation = animationCtrl.create()
		.addElement(baseEl.querySelector('ion-backdrop'))
		.fromTo('opacity', 0.01, 0);

	const wrapperElem = baseEl.querySelector('.alert-wrapper') as HTMLElement;
	wrapperElem.style.top = '0';
	const wrapperAnimation = animationCtrl.create()
		.addElement(wrapperElem)
		.beforeStyles({ 'opacity': 1 })
		.fromTo('transform', `translateY(-${baseEl.clientHeight}px)`, 'translateY(0px)');

	return animationCtrl.create()
		.addElement(baseEl)
		.easing('cubic-bezier(.36, .66, .3, 1.0)')
		.duration(DURATION)
		.addAnimation([backdropAnimation, wrapperAnimation]);
}