import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

@Component({
    selector: 'app-area-lists',
    templateUrl: './area-lists.page.html',
    styleUrls: ['./area-lists.page.scss'],
})
export class AreaListsPage implements OnInit {
    @Input() citylist: any;
    @Input() orginallist: any;
    constructor(
        public router: Router,
        public platform: Platform,
        public modalCtrl: ModalController,
    ) {
    }
    ngOnInit() {
        console.log(this.citylist, this.orginallist);
        this.orginallist = this.citylist;
    }
    getCityList = (ev: any) => {
        const val = ev.target?.value?.trim(); // Safely get and trim the input value
    
        // If originallist is empty, log a warning and exit
        if (!this.orginallist || Object.keys(this.orginallist).length === 0) {
            console.warn('originallist is empty. Ensure it is populated before filtering.');
            return;
        }
    
        // Ensure `originallist` is processed as an array
        const cityArray = Array.isArray(this.orginallist)
            ? this.orginallist
            : Object.values(this.orginallist || {});
    
        // Debug: Log the current `cityArray`
        console.log('City Array:', cityArray);
    
        // Reset `citylist` to the full list from `originallist`
        this.citylist = [...cityArray];
    
        // Perform filtering if the input value exists
        if (val) {
            this.citylist = cityArray.filter((city: any) => {
                const title = city.title || '';
                const a_title = city.a_title || '';
                const description = city.description || '';
    
                if (this.platform.isRTL) {
                    return (
                        title.includes(val) ||
                        a_title.includes(val) ||
                        description.toLowerCase().includes(val.toLowerCase())
                    );
                } else {
                    return (
                        title.includes(val) ||
                        description.toLowerCase().includes(val.toLowerCase())
                    );
                }
            });
        }
    
        // Debug: Log the filtered `citylist`
        console.log('Filtered City List:', this.citylist);
    };
    clearCityLists = () => {
        this.citylist = this.orginallist;
    }
    selectedCityItem = (cityData: any) => {
        this.modalCtrl.dismiss(cityData, 'close');
    }
    closeModal = () => {
        this.modalCtrl.dismiss(null, 'cancel');
    }
}
